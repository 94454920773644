import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://6ef3166fc034a1f128ddf4c5a5a3959c@o4507062272000000.ingest.us.sentry.io/4507062276849664",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function Main(){
  const [loader, setLoader] = React.useState(true);

  useEffect(()=>{
    //listen to window events
    window.addEventListener('domain_settings_loaded', function() {
      setLoader(false);
    });
  },[])
  if(!loader) return <></>;
  return <App />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);